import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import { ThemeProvider } from "@emotion/react";
import { legionTheme } from "@legion-ui/core";
import Overview from "./../../components/Overview.component.js";
import { overviewComponent } from "./../../components/constant";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ThemeProvider theme={legionTheme} mdxType="ThemeProvider">
      <div className='introgrid'>
        <Overview data={overviewComponent} mdxType="Overview" />
      </div>
    </ThemeProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      